@import "../../../styles/variables.scss";

.photo-view-list {
    &__nav-wrap {
        display: none;
    }

    &__item-wrap {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

    }

    &__item {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 100%;
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;

        img {
            /*display: block;*/
            display: inline-block;
        }
    }

    &__item + &__item {
        margin-left: 5px;
    }


    &__item-wrap::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 100px;
        height: 2px;
    }

    &__item-wrap::-webkit-scrollbar-thumb {
        background-color: #bfbfbf;
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
}

@media #{$viewport-xs} {
    .photo-view-list {
        position: relative;

        &__item-wrap {
            display: block;
            overflow-x: initial;
            overflow-y: initial;
            position: relative;
        }


        &__item + &__item {
            margin-left: 0;
        }

        &__item {
            max-width: none;
            height: 0;
            transition: 0.1s;
            overflow: hidden;

            img {
                opacity: 0;
                pointer-events: none;
                /*display: block;*/
                display: inline-block;
                margin: 0 auto;
            }
        }

        &__item.active {
            height: auto;
        }

        &__item.active img {
            opacity: 1;
        }

        &__nav-wrap {
            display: flex;
            flex-wrap: nowrap;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: -3px;
            z-index: 1;
        }

        &__nav {
            flex-grow: 1;
            flex-shrink: 1;
            position: relative;

            & > * {
                position: absolute;
                bottom: 0;
                left: 0px;
                right: 0px;
                height: 1px;
                pointer-events: none;
            }

            & + & > * {
                margin-left: 2px;
            }
        }

        &__nav:not(.active) > span {
            background-color: rgba(121,119, 121, 0.7);
        }
    }
}
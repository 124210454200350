@import "../../../../styles/functions/_util.scss";

.check-order {
}

.check-order-field {
    margin-bottom: rem-calc(13px);
}
.checkorder-modal__header{
    font-size: 18px;
    margin-bottom: 10px;
}
.checkorder-modal__subheader--block{
    font-weight: bold;
    margin-bottom:5px;
}
.checkorder-modal__block{
    margin-bottom: 15px;
}
.checkorder-modal {
    width: 50vw;
}
.checkorder-modal__contacts {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0 5px;
    }
}
.checkorder-modal__loading{
    text-align:center;
    padding: 20px 0;
}
@use "sass:math";

@import "../_settings.scss";
@import "../functions/_util.scss";

.product-categories-thin, product-categories-slim {
    margin-bottom: $margin-value-alt;
    position: relative;
}

.product-categories-thin {
    border: rem-calc(1px) #e2e3e4 solid;
    padding: rem-calc(25px) 0 0;

    .product-categories-item-thin {
        margin-bottom: rem-calc(-30px);
    }

    .products-view-price {
        text-align: center;
    }
}

.product-categories-thin-total {
    border-radius: $border-radius-base;
    font-size: $font-size-xmedium;
    text-align: center;
    padding: rem-calc(10px) rem-calc(25px);
    position: absolute;
    font-weight: bold;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: rem-calc(-22px);
}

.product-categories-thin-total-count {
    opacity: 0.5;
}

.product-categories-header-thin, .product-categories-header-slim {
    text-align: center;
    vertical-align: middle;
}

.product-categories-header-thin {
    margin-bottom: rem-calc(20px);

    &.no-products {
        margin-bottom: rem-calc(75px);
    }
}

.product-categories-header-container {
    display: table;
    margin: 0 auto;
}

.product-categories-header-slim-title, .product-categories-header-slim-count {
    display: table-cell;
}

.product-categories-header-slim {
    font-size: $font-size-normal;
}

.product-categories-header-slim-extend {
    height: (($line-height * $font-size-normal) * 2) + (math.div(($line-height * $font-size-normal), 2));
    overflow: hidden;
    position: relative;

    &:after {
        content: "";
        display: block;
        height: rem-calc(10px);
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: linear-gradient(to bottom, rgba(255,255,255,0.2) 0,rgba(255,255,255,1) 100%); /* W3C */
    }
}

.product-categories-header-slim-count {
    font-size: rem-calc(10px);
    padding-left: rem-calc(5px);
}

.product-categories-item-picture-slim {
    text-align: center;
    /*white-space: nowrap;

    &:before {
        display: inline-block;
        width: 0;
        height: 100%;
        content: "";
        vertical-align: middle;
    }*/
}

.product-categories-item-photo-link-slim {
    display: inline-block;
    /*vertical-align: middle;*/
    width:100%;
}

.product-categories-slim {
    box-sizing: border-box;
    margin: 0 rem-calc(-10px) 1.25rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.product-categories-item-slim {
        box-sizing: border-box;
}

.product-categories-item-inner-slim {
    border-radius: $border-radius-base;
    border: $border-width #e2e3e4 solid;
    margin: $padding-base;
    padding: rem-calc(20px);
}

.product-categories-view .products-view-tile .products-view-block {
    min-width: 25%;
    flex-basis: 25%;
}

.product-categories-view.products-view-tile .products-view-item {
    border: 0;

    &:hover {
        box-shadow: none;
    }
}
